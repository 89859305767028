import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import { Intro } from "components";

const IndexPage = ({ location, data }) => {
  return (
    <Layout location={location} pageTitle="Repurpose & Reprocess Sustainable Coffee Packaging">
      <Intro slides={data.allContentfulHomepageSlides.edges} overlay={true} />
    </Layout>
  );
};

export const query = graphql`
  query HomePageQuery {
    allContentfulHomepageSlides(sort: { fields: order }) {
      edges {
        node {
          id
          title
          background {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
          backgroundVideo {
            file {
                 url
                }
            }
          content {
            raw
          }
          productSpecs {
            file {
                url
            }
          }
          buildInstructions {
            file {
                url
            }
          }
          logo {
              file {
                  url
              }
           }  
        }
      }
    }
  }
`;

export default IndexPage;
